import * as ProjectAPI from 'api/project-api';
import { txt } from 'libs/i18n';
import { ApiError } from '@newtontechnologies/beey-api-js-client/receivers';
import { extractFirstValueByTagName } from 'libs/xml-utils';
import { sleep } from 'libs/utils';
import { savingStateHandler } from './saving-state-handler';
const LOOP_TIMEOUT = 0.1;
const MINIMUM_DELAY_FROM_LAST_SAVE = 7000;
const MINIMUM_INACTIVITY = 1000;
const MINIMUM_DELAY_AFTER_FAIL = 15000;
export default class DocumentSaver {
    constructor(project, onProjectUpdated, enqueueProjectUpdate, session, editorController, mediaProcessingStatus, onLeaveEditorRequested, onNotification) {
        this.handleActivity = () => {
            this.lastEditorActivity = this.getCurrentTime();
            if (this.isWaitingForSave) {
                return;
            }
            this.isWaitingForSave = true;
            void this.enqueueUpload();
        };
        this.cleanup = () => {
            this.isAborted = true;
            this.abortSave();
        };
        this.handleProjectUpdate = (project) => {
            if (project.currentTrsxId !== this.editorController.currentTrsxId) {
                // current trsx was updated unexpectedly. This should happen only when
                // someone else edited document.
                void this.resolveConflictingTrsx(project);
            }
        };
        this.enqueueUpload = async () => {
            await this.enqueueProjectUpdate(async () => {
                savingStateHandler.updateStatus('saving');
                while (!this.shouldUpload()) {
                    if (this.isAborted) {
                        throw Error('document saver was aborted, abandoning save');
                    }
                    // NOTE: In this case await in loop is exactly what we need.
                    // eslint-disable-next-line no-await-in-loop
                    await sleep(LOOP_TIMEOUT);
                }
                this.isWaitingForSave = false;
                await this.orderSave();
                return this.project;
            });
        };
        this.handleSaveFailed = (isProjectOutdated) => {
            this.failStreak += 1;
            global.logger.error(`document save failed. Streak: ${this.failStreak}`);
            this.lastSaveFinished = this.getCurrentTime();
            this.isSaveInProgress = false;
            this.isProjectOutdated = isProjectOutdated;
            void this.enqueueUpload();
        };
        this.handleSaved = () => {
            this.isProjectOutdated = false;
            this.lastSaveFinished = this.getCurrentTime();
            this.lastSuccessfulSaveStarted = this.lastSaveStarted;
            this.failStreak = 0;
            this.isSaveInProgress = false;
            global.logger.info('document saved.', {
                duration: this.lastSaveFinished - this.lastSaveStarted,
            });
        };
        this.abortSave = () => {
            const now = this.getCurrentTime();
            this.failStreak = 0;
            // This resets the scheduler so that we can attempt again if the user continues with editation
            this.lastSaveStarted = now;
            this.lastSaveFinished = now;
            this.isSaveInProgress = false;
            this.isWaitingForSave = false;
        };
        /*
          returns: isTrsxLoaded - true if new trsx was loaded from server into editor.
        */
        this.handleAccessTokenConflict = async () => {
            const { session, project } = this;
            global.logger.warn('Project saving failed due to access token', { accessToken: project.accessToken });
            const serverProject = await ProjectAPI.fetchProject(session.connection, project.id);
            if (serverProject === 'not-found') {
                global.logger.info('project not found after unsuccessful save');
                throw new Error('project not found after unsuccessful save');
            }
            if (project.currentTrsxId === serverProject.currentTrsxId) {
                // the conflict is not in trsx, no need to fix anything.
                await this.updateProject(serverProject);
                global.logger.info('project updated after unsuccesful save');
                return false;
            }
            return this.resolveConflictingTrsx(serverProject);
        };
        this.resolveConflictingTrsx = async (project) => {
            global.logger.info('conflicting current trsx');
            const theirTrsx = await ProjectAPI.fetchTrsx(this.session.connection, project, project.processingState !== 'InProgress' ? 'trsx' : 'partialTrsx');
            const theirEditorId = extractFirstValueByTagName(theirTrsx, 'EditorId');
            if (theirEditorId === this.editorController.getEditorId()) {
                // sometimes because of some weird network errors, we get false alarms that
                // someone else edited the document, even when that change was from the same
                // editor in the same window.
                global.logger.info('reloading conflicting trsx from same editor ignored');
                await this.updateProject(project);
                return false;
            }
            if (this.mediaProcessingStatus === 'completed') {
                global.logger.info('reloading trsx in editor');
                const lastEditorUsername = extractFirstValueByTagName(theirTrsx, 'LastEditorUserName');
                this.onNotification({
                    type: 'someone-edited',
                    details: lastEditorUsername !== null && lastEditorUsername !== void 0 ? lastEditorUsername : undefined,
                });
                // transcription is not in progress, so we can safely refresh editor content
                const currentPlaybackTime = this.editorController.playback.time;
                this.editorController.importTrsx(theirTrsx, false, project);
                this.editorController.playback.seekTo(currentPlaybackTime);
                void this.editorController.syncTextHighlightWithPlayback(true);
                savingStateHandler.updateStatus('saved');
                await this.updateProject(project);
                return true;
            }
            // there is no simple way to load their changes while the transcription
            // is running. The only safe way is to kick the user out and let him open the editor
            // again.
            global.logger.info('Cannot softly refresh. Reloading editor.');
            // we need alert here - normal notification would immediately disappear with reload
            // eslint-disable-next-line no-alert
            alert(`${txt('someoneEditedHead')} ${txt('someoneEdited')}`);
            this.onLeaveEditorRequested(); // leaving editor to projects page does not interrupt upload
            return false;
        };
        this.updateProject = async (project) => {
            this.editorController.currentTrsxId = project.currentTrsxId;
            this.editorController.partialTrsxId = project.partialTrsxId;
            await this.onProjectUpdated(project);
        };
        this.getCurrentTime = () => (new Date()).getTime();
        this.shouldUpload = () => {
            const now = this.getCurrentTime();
            if (this.isSaveInProgress) {
                // never start a new upload while previous is still running
                return false;
            }
            if (this.failStreak > 10) {
                global.logger.error('abandoning save, because we failed too many times.');
                this.onNotification({ type: 'upload-failed' });
                this.abortSave();
                return false;
            }
            if (this.failStreak === 1) {
                // after first fail, retry ASAP (typically this is access token conflict)
                return true;
            }
            if (this.failStreak > 0) {
                if (now < this.lastSaveFinished + MINIMUM_DELAY_AFTER_FAIL) {
                    // we need to wait after unsuccessful save to prevent network clogging
                    // See bug https://trello.com/c/DM4shCp7/1990
                    return false;
                }
                global.logger.info('retrying after unsuccessful save');
                return true;
            }
            if (this.lastEditorActivity < this.lastSaveStarted) {
                // never save if there is nothing to save
                return false;
            }
            if (now < this.lastSaveFinished + MINIMUM_DELAY_FROM_LAST_SAVE) {
                // we need to wait longer
                return false;
            }
            if (now < this.lastEditorActivity + MINIMUM_INACTIVITY) {
                // waiting, because user is still active
                return false;
            }
            // nothing stopped us from saving, so we save.
            global.logger.info('scheduled save');
            return true;
        };
        this.shouldIncludeTrsx = () => {
            return this.lastEditorActivity > this.lastSuccessfulSaveStarted;
        };
        this.orderSave = async () => {
            try {
                this.isSaveInProgress = true;
                const shouldIncludeTrsx = this.shouldIncludeTrsx();
                this.lastSaveStarted = this.getCurrentTime();
                await this.saveProject(shouldIncludeTrsx, this.isProjectOutdated);
            }
            catch (e) {
                global.logger.error('failed to save', {}, e);
                this.isSaveInProgress = false;
            }
        };
        this.saveProject = async (savingTrsx, isProjectOutdated) => {
            var _a, _b, _c;
            try {
                if (isProjectOutdated) {
                    // if we expect the project to be outdated (with outdated access token)
                    // we reload it in advance to avoid conflict.
                    await this.reloadProjectFromServer();
                }
                if (savingTrsx) {
                    global.logger.info('Saving trsx');
                    let trsx = null;
                    try {
                        const exporter = this.editorController.exportDocument(true);
                        trsx = exporter.exportTRSX();
                    }
                    catch (error) {
                        // retrying export would not help. The document is broken.
                        global.logger.error('failed to export trsx', {}, error);
                        this.abortSave();
                        this.onNotification({
                            type: 'export-failed',
                            // @ts-ignore
                            details: (_a = error === null || error === void 0 ? void 0 : error.message) !== null && _a !== void 0 ? _a : '',
                        });
                        savingStateHandler.updateStatus('failed-no-retry');
                        return;
                    }
                    const filesOnProject = await ProjectAPI.fetchProjectFiles(this.session.connection, this.project.id);
                    const activeTrsxLabel = (_c = (_b = filesOnProject.files.currentTrsx) === null || _b === void 0 ? void 0 : _b.find((currentTrsx) => currentTrsx.isActive)) === null || _c === void 0 ? void 0 : _c.label;
                    const serverProj = await ProjectAPI.fetchProject(this.session.connection, this.project.id);
                    const result = await ProjectAPI.uploadTrsx(this.session.connection, this.project, trsx, serverProj === 'not-found' || serverProj.processingState !== 'InProgress'
                        ? 'currentTrsx'
                        : 'partialTrsx', this.project.currentTrsxId === null, activeTrsxLabel);
                    if (result.isSuccess()) {
                        const serverProject = result.get();
                        await this.updateProject(serverProject);
                        global.logger.info('Project saving successful');
                        savingStateHandler.updateStatus('saved');
                        this.handleSaved();
                    }
                }
                else {
                    await this.reloadProjectFromServer();
                }
            }
            catch (error) {
                global.logger.error('unexpected error while saving', {}, error);
                await this.handleSaveError(error);
            }
        };
        this.handleSaveError = async (error) => {
            savingStateHandler.updateStatus('retrying');
            global.logger.error('Encountered save error.', {}, error);
            if (error instanceof ApiError && error.response.status === 409) {
                const isTrsxLoaded = await this.handleAccessTokenConflict();
                if (isTrsxLoaded) {
                    this.handleSaved();
                }
                else {
                    this.handleSaveFailed(false);
                }
            }
            else {
                try {
                    // When saving fails for unknown reason, it can possibly be false alarm.
                    // It is possible that server saved successfully, but we get "failed to fetch" error.
                    // To avoid conflicts, we fetch the project to get the correct access token.
                    await this.reloadProjectFromServer();
                    global.logger.error('Project updated after unsuccessful save.');
                    this.handleSaveFailed(false);
                }
                catch (error2) {
                    global.logger.error('Could not update project after failed save.');
                    // failed to reload the project. We need to get the correct access token before next save.
                    this.handleSaveFailed(true);
                    global.logger.error('Could not update project after failed save.', {}, error2);
                }
                global.logger.error('Project saving failed', {}, error);
            }
        };
        this.reloadProjectFromServer = async () => {
            const serverProject = await ProjectAPI.fetchProject(this.session.connection, this.project.id);
            if (serverProject === 'not-found') {
                global.logger.info('could not reload project');
                throw new Error('could not reload project');
            }
            await this.updateProject(serverProject);
        };
        const now = this.getCurrentTime();
        this.project = project;
        this.onProjectUpdated = onProjectUpdated;
        this.session = session;
        this.editorController = editorController;
        this.mediaProcessingStatus = mediaProcessingStatus;
        this.onLeaveEditorRequested = onLeaveEditorRequested;
        this.editorController = editorController;
        this.lastSaveFinished = now;
        this.lastSuccessfulSaveStarted = now;
        this.lastEditorActivity = 0;
        this.failStreak = 0;
        this.lastSaveStarted = now;
        this.isWaitingForSave = false;
        this.isSaveInProgress = false;
        this.isProjectOutdated = false;
        this.isAborted = false;
        this.enqueueProjectUpdate = enqueueProjectUpdate;
        this.onNotification = onNotification;
    }
    update(project, onProjectUpdated, session, editorController, mediaProcessingStatus, onLeaveEditorRequested) {
        this.project = project;
        this.onProjectUpdated = onProjectUpdated;
        this.session = session;
        this.editorController = editorController;
        this.mediaProcessingStatus = mediaProcessingStatus;
        this.onLeaveEditorRequested = onLeaveEditorRequested;
    }
}
